<template>
  <div>
    <!-- v-if="bolck == 1" -->
    <div id="morenext">
      <top :name="text" />
      <!-- 商品进度 -->
      <div
        v-if="wai == 0 && success_data.static_bootey!=3"
        :class="
          wai == 0 ? 'keys' : 'keys animate__animated animate__fadeInDown'
        "
      >
        <van-steps
          :active="active"
          v-if="active != 4 && success_data.static_bootey != 3"
          active-icon="success"
          :active-color="success_data.static_bootey == 3 ? 'red' : '#38f'"
        >
          <van-step>待支付</van-step>
          <van-step v-if="success_data.purchase.rmi_inventory != 0"
            >满团还差: {{ success_data.purchase.rmi_inventory }}人</van-step
          >
          <van-step v-if="success_data.purchase.rmi_inventory == 0"
            >已满团</van-step
          >
          <van-step v-if="success_data.static_bootey != (2 || 3)"
            >等待开奖</van-step
          >
          <van-step
            style="color: red"
            v-if="success_data.static_bootey == (2 || 3)"
            >开奖时间：{{ success_data.complete_time }}</van-step
          >
          <van-step v-if="success_data.static_bootey != 3">已开奖</van-step>
          <!-- <van-step
          v-if="success_data.static_bootey == 3 && success_data.is_prize == 1"
          style="color: red"
          >未中奖</van-step
        >
        <van-step
          style="color: red"
          v-if="success_data.static_bootey == 3 && success_data.is_prize == 2"
          >恭喜中奖啦</van-step
        > -->
        </van-steps>

      </div>
        <!-- 开奖后的top -->
        <div class="new_top" v-if="success_data.static_bootey == 3">
          <div class="font">
            <p
              style="
                font-size: 0.36rem;
                font-family: '微软雅黑';
                letter-spacing: 1px;
              "
            >
              {{
                success_data.is_prize == 1 ? "很遗憾您没有中奖" : "恭喜您已中奖"
              }}
            </p>
            <p style="font-size: 0.26rem">
              {{
                success_data.is_prize == 1
                  ? `平台会予以${success_data.compensation_amount}的现金补偿`
                  : "您的订单平台已确认"
              }}
            </p>
            <p style="font-size: 0.22rem">{{ success_data.draw_time }}</p>
          </div>
          <div class="img_div_ispize" style="flex: 0.6">
            <img
              v-if="success_data.is_prize == 2"
              style="width: 100%; height: 100%"
              src="../../static/asc.svg"
              alt=""
            />
            <img
              v-if="success_data.is_prize == 1"
              style="width: 100%; height: 100%"
              src="../../static/wei.png"
              alt=""
            />
          </div>
        </div>
        <!-- 开奖后的top End -->
      <div class="shopping_view">
        <div class="top_view">
          <p style="display: block; height: 100%; margin-left: 0.1rem">
            要旅行积分商城（拼团购）
          </p>
        </div>
        <div class="shopping">
          <div class="a">
            <img class="imghhh" :src="success_data.image_sku" alt="" />
          </div>
          <div style="position: relative; flex: 1.6">
            <p
              class="bn"
              style="
                font-size: 0.24rem;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              <span
                style="
                  padding: 0.02rem 0.1rem 0.02rem 0.1rem;
                  border: 1px solid red;
                  color: red;
                "
                >{{ success_data.purchase.count_user }}人团</span
              >
              {{ success_data.commodity_name }}
            </p>
            <p class="bn" style="font-size: 0.22rem; color: gray">
              {{ success_data.commodity_sku }}
            </p>
            <!-- <div class="zuoyou">
            <p style="color: red">￥{{ success_data.actual_price }}</p>
            <p>×1</p>
          </div>
          <p style="font-size: 0.24rem; color: #9e9e9e">
            未中奖得：{{ success_data.compensation_amount }}安慰金
          </p> -->
            <img
              v-if="success_data.is_prize == 1 && wai == 1"
              class="animate__animated animate__fadeIn"
              src="../../static/wzj.png"
              style="
                right: 0px;
                position: absolute;
                bottom: 0px;
                width: 1.4rem;
                height: auto;
              "
              alt=""
            />
            <img
              v-if="success_data.is_prize == 2 && wai == 1"
              class="animate__animated animate__fadeIn"
              src="../../static/zjbs.png"
              style="
                right: 0px;
                position: absolute;
                bottom: 0px;
                width: 1.4rem;
                height: auto;
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- <div style="background: white">
      
      <p
        style="
          color: red;
          font-size: 0.26rem;
          letter-spacing: 0.02rem;
          display: block;
          margin: 0.2rem;
        "
        v-if="success_data.is_prize == 1"
      >
        ! 关于未中奖全额退款并返利部分现金问题 <br />
        1.退款时间: 在开奖之后会在两分钟内退款到账 <br />
        2.现金返利: 在开奖之后将会打到账户余额中
      </p>
    
      <p
        style="
          color: red;
          font-size: 0.26rem;
          letter-spacing: 0.02rem;
          display: block;
          margin: 0.2rem;
        "
        v-if="success_data.is_prize == 2"
      >
        抽中平台商品的用户，平台会第一时间发货，请关注平台信息。
      </p>
    </div> -->
      <!-- 订单信息 -->
      <div class="next">
        <van-cell-group>
          <van-cell title="订单信息" arrow-direction="down" size="large" />
          <!-- 订单信息共有 -->
          <div>
            <van-cell title="订单编号" :value="success_data.order_number" />
            <!-- <van-cell title="创建时间" :value="success_data.establish_time" /> -->
            <van-cell title="下单时间" :value="success_data.payment_time" />
            <van-cell title="支付方式" value="微信支付" />
            <van-cell title="开奖编号" :value="this.bianhao" />
            <!-- <van-cell title="支付单号" :value="success_data.wx_pay_order" /> -->
          </div>
          <!-- 订单信息共有 End-->
          <!-- 已开奖显示的订单信息 -->
          <!-- <div v-if="success_data.static_bootey == 3">
          <van-cell :value="'开奖时间：'+success_data.draw_time" />
        </div> -->
          <!-- 已开奖显示的订单信息 End-->
          <!-- 物流单号-->
          <div v-if="success_data.group_logistics">
            <van-cell title="物流单号" :value="success_data.group_logistics" />
          </div>
          <!-- 物流单号 End-->
          <!-- 物流信息  需要后台回填-->
          <!-- <div v-if="">
          <van-cell title="物流公司" :value="success_data.order_number" />
          <van-cell title="物流单号" :value="success_data.order_number" />
        </div> -->
          <!-- 物流信息 End-->
        </van-cell-group>
      </div>
      <!-- 收货人信息 共有-->
      <div class="next">
        <van-cell-group>
          <van-cell
            title="收货人信息"
            arrow-direction="down"
            is-link
            size="large"
          />
          <!-- 收货人信息共有 -->
          <div>
            <van-cell title="姓名" :value="success_data.consignee.add_name" />
            <van-cell
              title="手机号码"
              :value="success_data.consignee.add_phone"
            />
            <van-cell title="地址" :value="success_data.consignee.add_add" />
          </div>
        </van-cell-group>
      </div>
      <!-- 价格信息 -->
      <div style="background: white">
        <div class="prlic_div">
          <div>
            <p style="font-size: 0.28rem">商品价格</p>
            <p style="font-weight: bold">￥{{ success_data.actual_price }}</p>
          </div>
          <div>
            <p style="font-size: 0.28rem">优惠金额</p>
            <p style="font-weight: bold">-￥0</p>
          </div>
          <div>
            <p style="font-size: 0.28rem">运输费</p>
            <p style="font-weight: bold">￥0</p>
          </div>
        </div>
        <p
          style="
            font-size: 0.26rem;
            padding: 0 0.2rem 0.2rem;
            align-items: center;
            display: flex;
            justify-content: flex-end;
          "
        >
          支付总额：<span style="font-weight: bold; font-size: 0.36rem"
            >￥{{ success_data.actual_price }}</span
          >
        </p>
      </div>

      <!-- 价格信息 End -->
      <!-- 物流信息 等待后台回填南航物流单号 待收货状态 -->
      <!-- <div class="wuliu_div">
      <van-steps direction="vertical" :active="0">
        <van-step>
          <h3>【城市】物流状态1</h3>
          <p>2016-07-12 12:40</p>
        </van-step>
        <van-step>
          <h3>【城市】物流状态2</h3>
          <p>2016-07-11 10:00</p>
        </van-step>
        <van-step>
          <h3>快件已发货</h3>
          <p>2016-07-10 09:30</p>
        </van-step>
      </van-steps>
    </div> -->
      <!-- 物流信息 End -->
    </div>

    <!-- 第一次进入动画效果 -->
    <div
      v-if="success_data.browse == 0"
      :class="
        div_istrue == 1 ? 'Mask animate__animated animate__flipOutX' : 'Mask'
      "

    >
      <div class="mask_div">
        <div
          :class="istrue == 1 ? 'animate__animated animate__fadeOutDown' : ''"
        >
          <img
            class="top_img animate__animated animate__backInDown"
            src="../../static/hongbao.svg"
            alt=""
          />
          <div
            @click="dianji"
            :class="
              click_get == 1
                ? 'kaijiang pokai animate__animated animate__backInDown'
                : 'kaijiang animate__animated animate__backInDown'
            "
          >
            <p class="animate__animated animate__swing">开</p>
          </div>
        </div>
      </div>
      <!-- <section v-if="istrue == 0">
        <div class="bottom animate__animated animate__flash">.</div>
      </section> -->

      <!-- 中奖 -->
      <p
        v-if="success_data.is_prize == 2 && xie == 1"
        class="p_gongxi animate__animated animate__backInDown"
      >
        <span class="animate__animated animate__bounceInDown"> 恭 </span>
        <span class="animate__animated animate__fadeInLeftBig"> 喜 </span>
        <span class="animate__animated animate__fadeInRight"> 您 </span>
        <span class="animate__animated animate__fadeInUp"> 中 </span>
        <span class="animate__animated animate__fadeInUpBig"> 奖 </span>
        <span class="animate__animated animate__fadeInTopLeft"> 了 </span>
        <span class="animate__animated animate__fadeInBottomRight"> ！ </span>
      </p>
      <!-- 未中奖 -->
      <p
        v-if="success_data.is_prize == 1 && xie == 1"
        class="p_gongxi animate__animated animate__backInDown"
      >
        <span class="animate__animated animate__bounceInDown"> 很 </span>
        <span class="animate__animated animate__fadeInLeftBig"> 遗 </span>
        <span class="animate__animated animate__fadeInRight"> 憾 </span>
        <span class="animate__animated animate__fadeInUp"> 被 </span>
        <span class="animate__animated animate__fadeInUpBig"> 抢 </span>
        <span class="animate__animated animate__fadeInTopLeft"> 走 </span>
        <span class="animate__animated animate__fadeInBottomRight"> 了 </span>
      </p>
    </div>
    <!-- 第一次进入动画效果 End -->
  </div>
</template>

<script>
import "animate.css";
import top from "../components/filltop.vue";
import wx from "weixin-jsapi";
export default {
  data() {
    return {
      text: "订单详情",
      active: 0,
      success_data: {},
      bolck: 0,
      click_get: 0,
      istrue: 0,
      xie: 0,
      div_istrue: 0,
      wai: 0,
      // 开奖编号
      bianhao: 0,
    };
  },
  components: {
    top,
  },
  methods: {
    status_function() {
      // this.success_data.static_bootey = 4
      // 根据后端传输过来的状态码 改变成对应的状态码 active
      // 拼团中
      "1" == this.success_data.static_bootey && (this.active = 1);
      // 待开奖
      "2" == this.success_data.static_bootey && (this.active = 2);
      // 已开奖
      "3" == this.success_data.static_bootey && (this.active = 3);
      // 退款
      "4" == this.success_data.static_bootey && (this.active = 4);
      // 如果订单状态是已取消?订单已关闭  ： 订单详情
      this.active == 4 ? (this.text = "订单已退款") : "订单详情";
    },
    dianji() {
      this.$https(
        "collage/isbrowse",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&order=" +
          this.success_data.order_number,
        "post",
        "false"
      ).then((res) => {
        if (res.data.code == "000000") {
          // 进行更改第一个次进入的动画 htpps
          this.click_get = 1;
          setTimeout(() => {
            this.istrue = 1;
            setTimeout(() => {
              this.xie = 1;
              setTimeout(() => {
                this.div_istrue = 1;
                setTimeout(() => {
                  this.wai = 1;
                }, 1000);
              }, 2000);
            }, 1000);
          }, 1000);
        }
      });
    },
  },
  mounted() {
    //     {
    //     "code": 1,
    //     "data": {
    //         "mchid": "1604187602",
    //         "amount": {
    //             "total": 200,
    //             "refund": 200,
    //             "payer_total": 200,
    //             "payer_refund": 200
    //         },
    //         "refund_id": "50301000292021121715483192676",
    //         "out_trade_no": "T2021121721639727059",
    //         "success_time": "2021-12-17T15:49:58+08:00",
    //         "out_refund_no": "RFD2021121721639727389",
    //         "refund_status": "SUCCESS",
    //         "transaction_id": "4200001138202112179488047018",
    //         "user_received_account": "支付用户零钱"
    //     }
    // }
  },
  created() {
    // 获取团购订单列表传输过来的详情信息
    this.success_data = JSON.parse(this.$route.params.success_data);
    this.status_function();
    if (this.success_data.browse == 1) {
      this.wai = 1;
    }
    this.bianhao = this.success_data.order_number.substr(-5);
    console.log(this.success_data);
  },
};
</script>


<style scoped>
.block {
  width: 100%;
  height: 0.94rem;
}
.block_position {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 0.3rem;
  background: white;
  border-top: 1px solid #efefef;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
}
.block_position p {
  display: block;
  color: white;
  font-size: 0.36rem;
  background: #1652c7;
  padding: 0.1rem;
  width: 80%;
  border-radius: 0.2rem;
  text-align: center;
}
#morenext {
  background: #f8f8f8;
}
.wuliu_div {
  width: 96%;
  height: auto;
  background: white;
  margin: 0 auto;
  /* margin-top: 0.2rem; */
}
.prlic_div {
  width: 100%;
  height: auto;
  padding: 0.2rem;
  font-family: inherit;
  border-bottom: 1px solid #ececec;
  font-size: 0.29rem;
  margin: 0.2rem auto;
}
.prlic_div div {
  display: flex;
  margin-top: 0.16rem;

  align-content: center;
  justify-content: space-between;
}
.font {
  font-size: 0.28rem;
  display: grid;
  align-content: space-around;
  color: white;
}
.new_top {
  width: 100%;
  padding: 0.26rem;
  justify-content: space-between;
  height: 3rem;
  display: flex;
  background: #008acb;
}
.img_div_ispize {
  width: 100%;
  height: auto;
}
/*  */
.next {
  width: 96%;
  height: auto;
  background: white;
  /* margin-top: 0.2rem; */
  margin: 0.2rem auto;
}
.shopping_view {
  width: 96%;
  margin: 0.3rem auto 0;
}
.top_view {
  width: 100%;
  height: auto;
  /* display: flex; */
  font-size: 0.26rem;
  border-bottom: 1px solid rgb(236, 236, 236);
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  background: white;
}
.shopping {
  width: 100%;
  background: white;
  padding: 0.1rem;
  display: flex;
  height: auto;
  /* margin: 0.3rem auto 0; */
  border-radius: 0.2rem;
}
.a {
  flex-basis: 41%;
  height: 2rem;
  display: flex;
  align-items: center;
  /* border: 0.01rem solid rgb(204, 204, 204); */
  border-radius: 0.14rem;
  flex: 1;
  justify-content: center;
  margin-top: 0.2rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.imghhh {
  width: 80%;
  height: auto;
}
.shopping:nth-child(2) {
  font-size: 0.3rem;
}
.zuoyou {
  display: flex;
  font-size: 0.28rem;
  align-content: center;
  justify-content: space-between;
}
.bn {
  margin: 0.2rem 0 0.2rem 0;
}

/* 动画效果 */
.Mask {
  width: 100%;
  height: 100%;

  position: fixed;
  /* z-index: 99999999999999999999999999999999999999999999999999999999999999999999999999; */
  top: 0;
  right: 0;
  perspective: 11rem;
  display: flex;
  justify-content: center;
  background: black;
  left: 0;
  bottom: 0;
}
.mask_div {
  width: 100%;
  height: 100%;
  position: relative;
  animation: bottom_top 1s linear infinite;
}
.top_img {
  width: 4rem;
  height: auto;
  position: absolute;
  right: 0;
  z-index: 1;
  left: 0;
  top: -2rem;
  bottom: 0;
  margin: auto;
}
@keyframes bottom_top {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(40px);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.bottom_di {
  width: 6rem;
  height: auto;
  position: absolute;
  transform: rotate(360deg);
  left: 0;
  right: 0;
  margin: 6rem auto;
}

section {
  cursor: pointer;
  position: absolute;
  width: 3rem;
  margin: 5rem auto;
  /* height: 2rem; */
  z-index: 0;
  border-radius: 20px;
  transform-style: preserve-3d;
  animation: rotate 10s linear infinite;
  background-size: cover;
}
@keyframes rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}
section div {
  position: absolute;
  top: 0;
  left: 0;
  top: 0;
}
section div.bottom {
  width: 6rem;
  height: auto;
  border-radius: 50%;
  left: -170px;
  top: 35px;
  background: url("../../static/m.png") center center;
  background-size: cover;
  transform: rotateX(90deg);
  box-shadow: 0 0 90px #111;
  filter: grayscale(1);
  color: white;
  font-size: 50px;
  text-align: center;
  line-height: 600px;
  text-shadow: 0 5px 10px gray;
}
.pokai {
  transform-style: preserve-3d;
  animation: fanzhuan 0.1s linear infinite;
}
@keyframes fanzhuan {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}
.kaijiang {
  color: white;
  position: absolute;

  top: 0;
  width: fit-content;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 9px #8bff4f;
  align-items: center;
  right: 0;
  background: #d4ab14;
  height: fit-content;
  left: 0;
  padding: 0.2rem;
  border-radius: 60%;
  z-index: 9999999;
  bottom: 0;
  margin: auto;
}
.kaijiang p {
  padding: 0 0.2rem 0 0.2rem;
  font-family: inherit;
}
.p_gongxi {
  color: #d1481f;
  font-weight: bold;
  font-size: 0.8rem;
  position: absolute;
  font-family: revert;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 动画效果 End */
</style>
